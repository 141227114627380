import { required } from 'vuelidate/lib/validators';

export const accountValidation: any = {
  name: { required }
};

export const accountData = {
  name: null,
  type1Name: null,
  type1Code: '000',
  type2Name: null,
  type2Code: '00',
  dummy: true
};
