import { BaseClass } from '../base/base.service';

export class QuickBooksProvider extends BaseClass {
  constructor() {
    super('quickbooks');
  }

  async glCodesSync(data) {
    const res = await this.put(`/gl-codes/sync`, data);
    return res;
  }
}

export const QuickBooksService = new QuickBooksProvider();
