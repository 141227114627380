










































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { MasterDataService } from '@/shared/services/mater-data/master-data.service';
import { convertDateFieldToAPIFormat } from '@/utils/date.util';
import { validationMixin } from 'vuelidate';
import { accountValidation, accountData } from '../@types/account.model';
import { ToastHelper } from '@/utils/toast.util';
import Loading from '@/shared/components/Loading.vue';

Component.registerHooks(['validations']);

@Component({
  components: {
    Loading
  },
  mixins: [validationMixin]
})
export default class AccountModalQb extends Vue {
  @Prop() accountId: any;

  loading = false;
  convertDateFieldToAPIFormat = convertDateFieldToAPIFormat;
  account = null;
  form: any = this.account;
  realCode2 = '0';
  errorModal = false;

  constructor() {
    super();
  }

  async changeAccount() {
    this.account = await MasterDataService.getGLCodeById(this.accountId);
    this.form = this.account;
  }

  created() {
    if (this.accountId) {
      this.changeAccount();
    } else {
      this.account = { ...accountData };
      this.form = this.account;
    }
  }

  validations() {
    return {
      form: accountValidation
    };
  }

  validateState(val: any) {
    if (this.$v && this.$v.form && this.$v.form[val]) {
      const { $dirty, $error }: any = this.$v.form[val];
      return $dirty ? !$error : null;
    }
  }

  closeAccount() {
    this.$bvModal.hide('account-modal-qb');
  }
}
