



































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { MasterDataService } from '@/shared/services/mater-data/master-data.service';
import { convertDateFieldToAPIFormat } from '@/utils/date.util';
import { validationMixin } from 'vuelidate';
import { accountValidation, accountData } from '../@types/account.model';
import { ToastHelper } from '@/utils/toast.util';
import Loading from '@/shared/components/Loading.vue';
import { removeEmptyFormProperties } from '@/utils/form.util';

Component.registerHooks(['validations']);

@Component({
  components: {
    Loading
  },
  mixins: [validationMixin]
})
export default class AccountModal extends Vue {
  @Prop() accountId: any;

  loading = false;
  convertDateFieldToAPIFormat = convertDateFieldToAPIFormat;
  saveClicked = false;
  type1Options = [
    { value: 'Revenues', text: 'Revenues' },
    { value: 'Cost of Services', text: 'Cost of Services' }
  ];
  account = null;
  form: any = null;
  errorModal = false;

  constructor() {
    super();
  }

  async changeAccount() {
    this.account = await MasterDataService.getGLCodeById(this.accountId);
    this.form = this.account;
  }

  created() {
    if (this.accountId) {
      this.changeAccount();
    } else {
      this.account = { ...accountData };
      this.form = this.account;
    }
  }

  changeType1() {
    if (this.account.type1Name === 'Revenues') {
      this.account.type1Code = '400';
    } else if (this.account.type1Name === 'Cost of Services') {
      this.account.type1Code = '500';
    } else {
      this.account.type1Code = '000';
    }
  }

  validations() {
    return {
      form: accountValidation
    };
  }

  validateState(val: any) {
    if (this.$v && this.$v.form && this.$v.form[val]) {
      const { $dirty, $error }: any = this.$v.form[val];
      return $dirty ? !$error : null;
    }
  }

  closeAccount() {
    this.$bvModal.hide('account-modal');
  }

  async createAccount() {
    this.saveClicked = true;
    removeEmptyFormProperties(this.account);

    if (this.isFormInValid()) {
      return;
    }

    const toSendData = { ...this.account };

    if (this.accountId) {
      try {
        this.loading = true;
        delete toSendData.id;
        await MasterDataService.updateGLCode(this.accountId, toSendData);
        this.loading = false;

        ToastHelper.show(
          'Account code update',
          'Update successful',
          5000,
          'success'
        );
      } catch (err) {
        ToastHelper.show('Account code update', err.message, 5000, 'danger');
      }
    } else {
      try {
        this.loading = true;
        await MasterDataService.createGLCode(toSendData);
        this.loading = false;

        ToastHelper.show(
          'Account code create',
          'Create successful',
          5000,
          'success'
        );
      } catch (error) {
        switch (error.response.status) {
          case 409:
            ToastHelper.show(
              'Account code create',
              `Code ${toSendData.type1Code}${toSendData.type2Code} is in the List. Please input other code.`,
              5000,
              'danger'
            );
            break;
          default:
            ToastHelper.show(
              'Account code create',
              error.message,
              5000,
              'danger'
            );
            break;
        }
      }
    }

    this.$bvModal.hide('account-modal');
  }

  isFormInValid() {
    this.$v.form.$touch();

    if (this.$v.form.$anyError) {
      this.errorModal = true;
    }
    return this.$v.form.$anyError;
  }
}
